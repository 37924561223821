<template>
    <!-- <div class="body-color"> -->
  
    <div
      align="left"
      :class="{
        'ma-0': $vuetify.breakpoint.smAndDown,
        'ma-5': $vuetify.breakpoint.mdAndUp,
      }"
      xs12
      sm6
      md4
    >
      <notification-popup
        :dialog="notifFailed"
        :contentMsg="failedAuthMsg"
        :headerMsg="$t('Failed')"
        :nameBtnYes="$t('Cancel')"
        :nameBtnNo="$t('labelNo')"
        colorIcon="danger"
        typeModal="transaction"
        Icon="mdi-close-circle"
        disableBtnNo="true"
        :onHandlerYes="buttonOkeFailed"
      />
          <notification-popup
        :dialog="notifSecurity"
        :contentMsg="$t('LockedIpAccount')"
        :headerMsg="$t('Failed')"
        :nameBtnYes="$t('Cancel')"
        :nameBtnNo="$t('labelNo')"
        colorIcon="danger"
        typeModal="transaction"
        Icon="mdi-close-circle"
        disableBtnNo="true"
        :onHandlerYes="buttonOkeFailed"
      />
      <div class="body-login">
        <v-form v-model="valid">
        <!-- <div align="left" v-if="fromWhere == 'validation'">
          <h3 class="h3-title-black">{{ $t("Welcomeback") }}</h3>
          <p class="title-login">{{ $t("PleaseRelogin") }}</p>
        </div>
  
        <div align="left" v-else-if="fromWhere == 'register'">
          <h3 class="h3-title-black">{{ $t("Welcomeback") }}</h3>
          <p class="title-login">{{ $t("CheckYourEmail") }}</p>
        </div> -->
  
        <div align="left">
          <h3 class="h3-title">{{ $t("Welcomeback") }}</h3>
          <p class="title-login">{{ $t("LogintoyourAccount") }}</p>
        </div>
  
        <v-row align="center">
          <v-col cols="12" class="marginzero">
            <label class="label-content">{{ $t("email") }}</label>
            <v-text-field
              height="20"
              name="email"
              :color="$partnerACL.getButtonColor()"
              v-validate="'required|email'"
              :rules="[emailRules.required, emailRules.valid]"
              v-model="email"
              :placeholder="$t('e.g.') + ' johndoe@mail.com'"
              required
              dense
              outlined
            ></v-text-field>
          </v-col>
  
          <v-col cols="12" class="marginzero">
            <label class="label-content">{{ $t("password") }}</label>
            <v-text-field
              v-model="password"
              :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
              name="password"
              :color="$partnerACL.getButtonColor()"
              v-validate="'required'"
              :rules="[passwordRules.required, passwordRules.min, passwordRules.max]"
              :type="show ? 'text' : 'password'"
              dense
              :placeholder="$t('Enteryourpassword')"
              class="input-group--focused"
              @click:append="show = !show"
              v-on:keyup.enter="login"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
  
        <v-checkbox class="margintop-zero" v-model="remember_me" :color="$partnerACL.getButtonColor()">
          <template v-slot:label>
            <div>
              <p class="label-rememberme">{{ $t("RememberMe") }}</p>
            </div>
          </template>
        </v-checkbox>
  
        <p class="btn btn--flat">
          <router-link class="no-underline" :to="'/forgotpassword'">
            {{ $t("Forgotyourpassword") }}
          </router-link>
        </p>
        <div class="footer justif-content-even">
          <ac-button 
            :outlined="true"
            :name="$t('CreateAccount')" 
            :on-click-handler="register"
            :width="'40%'"
          />
          <ac-button 
            :name="$t('Next')" 
            :on-click-handler="login"
            :is-disabled="buttonLogin == false && valid==false"
            :width="'40%'"
          />
        </div>
        </v-form>
      </div>
    </div>
    <!-- </div> -->
  </template>
  
  <script>
  import AcButton from "@/components/AcButton";
  
  import NotificationPopup from "@/components/NotificationPopup.vue";
  // import CheckBoxAc from "@/components/CheckBox"
  import store from "@/store/index";
  
  export default {
    name: "LoginV2",
    components: {
      AcButton,
      NotificationPopup
    },
    data() {
      return {
        fromWhere: this.$route.params.fromWhere
          ? this.$route.params.fromWhere
          : "",
        notifFailed: false,
        notifSecurity: false,
        valid: false,
        email: "",
        password: "",
        buttonLogin: false,
        remember_me: false,
        show: false,
        failedAuthMsg: '',
        emailRules: {
          required: value => !!value || this.$t("EmailRequired"),
          valid: value => !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || this.$t("EmailValidation"),
        },
        passwordRules: {
          required: value => !!value || this.$t("ConfirmPasswordRequired"),
          min: value => value.length > 7 || this.$t("ConfirmPasswordMin"),
          max: value => value.length <= 200 || this.$t("ConfirmPasswordMax"),
        },
      };
    },
    computed: {
      validateForm() {
        return !this.errors.any() && this.valid && this.email != "" && this.password != "";
      },
      landingPage() {
        return this.$partnerACL.getACLConfiguration().landing_page;
      },
    },
    watch: {
      valid() {
        this.buttonLogin = (this.valid)
      },
    },
    mounted() {
      store.commit("auth/IS_LOADING", false);
    },
    methods: {
      register() {
        this.$router.push({ name: "RegisterV2" });
      },
      login() {
        const fcmToken = localStorage.getItem('fcmToken')
        const payload = {
          remember_me: this.remember_me,
          email: this.email,
          password: this.password,
          fcm_token: fcmToken
        };
        this.$store
          .dispatch("auth/login", payload)
          .then((response) => {
            if (response.status == "inactive") {
              this.$router.push({
                name: "OTPValidate",
                params: { data: response },
              });
            } else if (response.status) {
              this.$router.push({ name: this.landingPage.menu_name || "Home" });
            } else if (response.message == "securityWarning") {
              this.notifSecurity = true;
              this.buttonLogin = false;
            }
            else {
              console.log(err.response);
              this.failedAuthMsg = this.$t('loginfailed')
              this.notifFailed = true;
            }
          })
          .catch((err) => {
            console.log(err.response);
            // this.failedAuthMsg = this.$t(err.response.data.message)
            // this.notifFailed = true;
          });
      },
      buttonOkeFailed() {
        this.notifFailed = false;
        this.notifSecurity = false;
      },
    },
  };
  </script>
  
  <style scoped>
  .justif-content-even {
    justify-content: space-evenly !important;
  }
  
  .h3-title-black {
    color: #000;
    font-size: 14px;
    font-weight: 800;
    line-height: 26px;
    margin-bottom: 2px;
  }
  
  /* If the screen size is 601px or more, set the font-size of <div> to 80px */
  @media only screen and (min-width: 601px) {
    .v-text-field .v-input__control .v-input__slot {
      min-height: auto !important;
      display: flex !important;
      align-items: center !important;
    }
  
    .body-color {
      background-color: #f5fdf7;
      height: 100%;
      width: 100%;
    }
  
    .body-login {
      /* position: fixed; */
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 20px;
      padding-bottom: 30px;
    }
  
    .h3-title {
      color: #000;
      font-size: 22px;
      line-height: 26px;
      margin-bottom: 2px;
    }
  
    .title-login {
      color: #000;
      font-size: 16px;
      line-height: 16px;
      margin-top: 2px;
    }
  
    .label-content {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
  
    .label-rememberme {
      color: #000;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      padding-top: 15px;
    }
  
    .warning-username {
      font-weight: 300;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.85);
    }
  
    .footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  
  /* If the screen max size is 600px, set the font-size of <div> to 80px */
  @media only screen and (max-width: 600px) {
    .marginzero {
      margin-bottom: -20px;
    }
  
    .margintop-zero {
      margin-top: -10px;
    }
  
    .no-underline {
      text-decoration: none;
      color: #000;
    }
  
    .body-color {
      background-color: #f5fdf7;
      height: 100%;
      width: 100%;
    }
  
    .body-login {
      /* position: fixed; */
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 20px;
      padding-bottom: 30px;
    }
  
    .h3-title {
      color: #000;
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 2px;
    }
  
    .title-login {
      color: #000;
      font-size: 14px;
      line-height: 19px;
      margin-top: 2px;
      margin-bottom: 2px;
    }
  
    .label-content {
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }
  
    .label-rememberme {
      color: #000;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      padding-top: 15px;
    }
  
    .warning-username {
      margin-top: -1.5rem;
      margin-left: 10px;
      font-weight: 300;
      font-size: 10px;
      line-height: 12px;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.85);
    }
  
    .footer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  </style>